<template>
  <Preloader v-if="isStorageInfoLoading" />
  <div class="event-page" v-else>
    <div class="event-page__body">
      <Title border :label="title" icon />
      <div class="event-page__info">
        <div class="event-page__type">
          <p>Тип</p>
          <p>{{ currentProjectInfo?.type?.title }}</p>
        </div>
        <div class="event-page__format">
          <p>Формат</p>
          <p>{{ currentProjectInfo?.format?.title }}</p>
        </div>
        <div class="event-page__date">
          <p>Даты проведения</p>
          <p>
            {{ allPeriodWorking(currentProjectInfo?.settings?.stages?.data) }}
          </p>
        </div>
        <div class="event-page__media">
          <p>Социальные сети</p>
          <div class="event-page__icons">
            <a
              :href="social?.href"
              v-for="(social, index) in currentProjectInfo?.socials?.data"
              :key="index"
              target="_blank"
            >
              <Icon :type="social?.type" />
            </a>
          </div>
        </div>
      </div>
      <div class="event-page__about">
        <div class="event-page__about__title event-title">О мероприятии</div>
        <div
          class="event-page__about__text"
          v-html="currentProjectInfo?.description.replace(/\n/g, '<br>')"
        />
      </div>
      <div class="event-page__stages">
        <div class="event-page__stages__title event-title">
          Этапы проведения мероприятия
        </div>
        <ul class="event-page__stages__list">
          <li
            v-for="(stage, index) in currentProjectInfo?.info_block_parts.data"
            :key="index"
          >
            {{ stage?.title }}:
            <span>
              {{ stage?.value }}
            </span>
          </li>
        </ul>
      </div>
      <div class="event-page__docs">
        <div class="event-page__docs__title event-title">
          Нормативные документы
        </div>
        <div class="event-page__docs__links">
          <a
            v-for="(doc, index) in currentProjectInfo?.info_documents?.data"
            :href="docLink(doc?.url_original)"
            :key="index"
            target="_blank"
            download=""
          >
            {{ doc?.name }}
          </a>
        </div>
      </div>
      <div
        class="event-page__category-title event-title"
        v-if="categoriesCount > 1"
      >
        Категории
      </div>
      <div class="event-page__category">
        <EventPageCategory
          v-for="(category, index) in currentProjectInfo?.settings?.categories"
          :key="index"
          :data-array="category"
          :project-info="projectInfo"
          :index="index"
          :categories-count="categoriesCount"
        />
      </div>
      <div class="event-page__projects">
        <router-link to="/">Вернуться к списку конкурсов и премий</router-link>
      </div>
    </div>
    <div class="event-page__card">
      <EventPageCard
        :socials-array="currentProjectInfo?.socials?.data"
        :all-period="
          allPeriodWorking(currentProjectInfo?.settings?.stages?.data)
        "
        :poster="poster"
        :projectKey="projectKey"
      />
    </div>
  </div>
</template>

<script>
import EventPageCategory from "@/components/EventSection/EventPageCategory";
import EventPageCard from "@/components/EventSection/EventPageCard";
import Title from "@/components/Blocks/Title";
import { mapGetters, mapState } from "vuex";
import eventPageCompetition from "@/enums/eventPageСompetition";
import Icon from "@/components/Blocks/Icon";
import { allPeriodWorking } from "@/utils/helpers";
import Preloader from "@/components/Blocks/Preloader";
import { createRequest } from "@/api/requestBuilder";
import requestConfigs from "@/api/requestConfigs";

export default {
  name: "EventPage",
  components: { Preloader, Icon, Title, EventPageCard, EventPageCategory },
  data() {
    return {
      eventPageCompetition,
    };
  },
  computed: {
    categoriesCount() {
      return this.currentProjectInfo?.settings?.categories.length;
    },
    title() {
      return `${this.currentProjectInfo?.title}`;
    },
    poster() {
      let poster;
      if (this.currentProjectInfo?.image_poster) {
        // poster = `${process.env.VUE_APP_BACKEND_URL}${this.currentProjectInfo?.image_poster}`;
        poster = this.currentProjectInfo?.image_poster_html;
      }
      return poster;
    },
    currentCompetition() {
      return this.eventPageCompetition.find((item) => {
        return item.id === +this.$route.params.id;
      });
    },
    projectInfo() {
      let newProjectList = JSON.parse(
        JSON.stringify(Object.values(this.projectsList))
      );

      return newProjectList?.find((item) => {
        return item.id === +this.$route.params.id;
      });
    },
    currentProjectInfo() {
      let newSettings;
      if (this.projectInfo) {
        newSettings = JSON.parse(JSON.stringify(this.projectInfo));
      }

      newSettings?.settings?.categories?.forEach((item) => {
        let buffer = this.currentCompetition?.categories.find((i) => {
          return item.category_id === i.category_id;
        });
        let bufferStatement = this.statementsList?.find((i) => {
          return (
            i.info_project.id === newSettings.id &&
            i.version === newSettings.version &&
            i.info_category.id === buffer.category_id
          );
        });

        item.link = buffer?.link;
        item.stage = newSettings?.settings.stages.data.find((i) => {
          return i.stage_key === "open_registration";
        });
        item.project_id = this.currentCompetition?.id;
        item.version = newSettings?.version;
        item.id = bufferStatement?.id;
        item.status = bufferStatement?.status;

        return item;
      });

      return newSettings;
    },
    projectKey() {
      return this.currentProjectInfo?.key;
    },
    ...mapGetters(["projectsList"]),
    ...mapState(["isStorageInfoLoading", "statementsList"]),
  },
  methods: {
    docLink(value) {
      return `${process.env.VUE_APP_BACKEND_URL}${value}`;
    },
    loadStatementsLit() {
      this.isListLoading = true;
      createRequest(requestConfigs.GETStatementList)
        .then((response) => {
          this.$store.commit("setStatementsList", response.data);
        })
        .finally(() => {
          this.isListLoading = false;
        });
    },
    allPeriodWorking,
  },
  mounted() {
    this.loadStatementsLit();
  },
};
</script>

<style lang="scss">
.event-page {
  display: flex;
  gap: 45px;
  &__body {
    flex: 1 1 auto;
  }
  &__card {
    justify-self: flex-end;
    @include adaptive(desktop-small) {
      display: none;
    }
  }
  &__title {
    display: flex;
    gap: 10px;
    font-size: 3rem;
    line-height: 3.6rem;
    font-weight: 500;
    padding-bottom: 20px;
    margin-bottom: 44px;
    border-bottom: 1px solid var(--background--btn--profile--hover);
    @include adaptive(tablet-big) {
      padding-bottom: 15px;
      margin-bottom: 30px;
    }
    @include adaptive(phone) {
      font-size: 1.8rem;
      line-height: 2.2rem;
      margin-bottom: 20px;
    }
    & span {
      position: relative;
      top: 3px;
      font-size: 2.4rem;
      @include adaptive(phone) {
        font-size: 1.4rem;
        top: 0;
      }
    }
  }
  &__info {
    display: flex;
    gap: 40px;
    margin-bottom: 30px;
    @include adaptive(desktop-small) {
      justify-content: space-around;
    }
    @include adaptive(tablet-small) {
      flex-direction: column;
      gap: 12px;
    }
    @include adaptive(phone) {
      font-size: 1.4rem;
      line-height: 1.7rem;
    }
  }
  &__type,
  &__format,
  &__date,
  &__media {
    & p:first-child {
      color: var(--banner--color);
    }
  }
  &__date,
  &__media {
    display: none;
    @include adaptive(desktop-small) {
      display: block;
    }
  }
  &__media {
    & p:first-child {
      margin-bottom: 10px;
    }
    .event-page__icons {
      display: flex;
      gap: 10px;
      margin-left: 5px;
      & i {
        font-size: 1.8rem;
        &:first-child {
          color: #00bfff;
        }
        &:last-child {
          color: #2683ed;
        }
      }
    }
  }
  &__about {
    margin-bottom: 32px;
    padding: 24px;
    background-color: #fce8ef;
    border-radius: 8px;
    @include adaptive(phone) {
      padding: 18px;
      &__text {
        font-size: 1.4rem;
        line-height: 2rem;
      }
    }
  }
  &__stages {
    margin-bottom: 32px;
    ul {
      list-style: disc;
      margin-left: 15px;
    }
    &__list {
      span {
        color: var(--red);
      }
      @include adaptive(phone) {
        font-size: 1.4rem;
        line-height: 2rem;
      }
    }
  }
  &__docs {
    margin-bottom: 40px;
    @include adaptive(phone) {
      margin-bottom: 30px;
    }
    &__links {
      font-size: 1.4rem;
      line-height: 1.7rem;
      display: inline-flex;
      flex-direction: column;
      gap: 16px;
      word-break: break-word;
      a {
        color: inherit;
        &:before {
          content: "\e920";
          font-family: icomoon;
          color: var(--red);
          margin-right: 10px;
        }
      }
      @include adaptive(phone) {
        font-size: 1.2rem;
        line-height: 1.4rem;
      }
    }
  }
  &__projects {
    a {
      font-size: 18px;
      font-weight: 500;
      color: #1414d3;
      &:hover {
        -webkit-text-stroke-width: 0.05rem;
      }
    }
  }
}
.event-title {
  font-size: 2.2rem;
  line-height: 2.7rem;
  font-weight: 500;
  margin-bottom: 24px;
  @include adaptive(phone) {
    font-size: 1.8rem;
    line-height: 2.2rem;
    margin-bottom: 12px;
  }
}
</style>
