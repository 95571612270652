export default [
  {
    id: 1,
    categories: [
      { category_id: 3, link: "ExcellentStudentProfile" },
      { category_id: 2, link: "SocialActiveProfile" },
    ],
  },
  {
    id: 2,
    categories: [
      { category_id: 5, link: "StudentYearIndividualNomination" },
      { category_id: 6, link: "StudentYearCollectiveNomination" },
      { category_id: 7, link: "StudentYearGrandPrixNomination" },
    ],
  },
  {
    id: 3,
    categories: [
      { category_id: 11, link: "AchievementYearIndividualNomination" },
      { category_id: 12, link: "AchievementYearCollectiveNomination" },
      { category_id: 13, link: "AchievementYearGrandPrixNomination" },
    ],
  },
  {
    id: 4,
    categories: [{ category_id: 10, link: "HousingStockProfile" }],
  },
  {
    id: 5,
    categories: [
      { category_id: 21, link: "LigaForumFirstShiftProfile" },
      { category_id: 22, link: "LigaForumSecondShiftProfile" },
      { category_id: 23, link: "LigaForumThirdShiftProfile" },
    ],
  },
];
