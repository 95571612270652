<template>
  <div class="event-page-card">
    <div class="event-page-card__img">
      <!--      <img :src="poster" alt="" />-->
      <div v-html="poster"></div>
    </div>
    <div class="event-page-card__date">
      <p class="event-page-card__date__title">Даты проведения</p>
      {{ allPeriod }}
    </div>
    <div class="event-page-card__social">
      <div class="event-page-card__social__title">Социальные сети</div>
      <div class="event-page-card__social__icon">
        <a
          :href="social.href"
          v-for="(social, index) in socialsArray"
          :key="index"
          target="_blank"
        >
          <Icon :type="social.type" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/Blocks/Icon";
export default {
  name: "EventPageCard",
  components: { Icon },
  props: ["socialsArray", "allPeriod", "poster", "projectKey"],
};
</script>

<style lang="scss">
.event-page-card {
  padding: 20px;
  border: 1px solid var(--background--btn--profile--hover);
  border-radius: 5px;
  &__img {
    position: relative;
    min-height: 200px;
    min-width: 240px;
    padding: 20px 0;
    border: 1px solid var(--background--btn--profile--hover);
    border-radius: 5px;
    margin-bottom: 20px;
    & img {
      @include full-absolute();
      object-fit: scale-down;
      border-radius: 5px;
    }
  }
  &__date {
    font-size: 1.4rem;
    line-height: 1.7rem;
    font-weight: 500;
    margin-bottom: 12px;
    &__title {
      color: var(--banner--color);
      font-size: 1.4rem;
      line-height: 1.7rem;
      font-weight: 400;
    }
  }
  &__social {
    &__title {
      color: var(--banner--color);
      font-size: 1.4rem;
      line-height: 1.7rem;
      font-weight: 400;
      margin-bottom: 6px;
    }
    &__icon {
      display: flex;
      gap: 16px;
      margin-left: 3px;
      & i {
        font-size: 2.4rem;
        &:first-child {
          color: #00bfff;
        }
        &:last-child {
          color: #2683ed;
        }
      }
    }
  }
}
</style>
