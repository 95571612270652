<template>
  <div class="event-category" :class="className">
    <div class="event-category__title" v-if="categoriesCount > 1">
      <span>{{ index + 1 }}. {{ dataArray?.title }}</span>
      <p v-if="currentShiftInfo">
        ({{
          `${currentShiftInfo?.beginning_at_format} - ${currentShiftInfo.ended_at_format}`
        }})
      </p>
      <a :href="linkLF[dataArray?.category_id]" target="_blank">
        <Icon type="View-On" v-if="dataArray?.project_id === 5" />
      </a>
    </div>
    <div class="event-category__profile" v-if="categoriesCount > 1">
      <p class="event-category__profile__title">Профиль:</p>
      {{ dataArray?.title }} – {{ dataArray?.description }}
    </div>
    <div class="event-category__request">
      <div class="event-category__request__date">
        <p class="event-category__request__date__title">
          Дата заявочной кампании: {{ dataArray?.stage?.start_at_format }} -
          {{ dataArray?.stage?.end_at_format }}
        </p>
        <p v-if="currentShiftInfo" class="event-category__request__date__title">
          {{
            `${currentShiftInfo?.text} - ${currentShiftInfo?.min_access_age} - ${currentShiftInfo?.max_access_age} лет`
          }}
        </p>
        <Status
          :statusValue="+currentStatus"
          :is-status-admitted="isStatementAddmitted"
          :text="
            dataArray?.project_id === 5 && currentStatus === '100'
              ? 'Приглашён на форум'
              : dataArray?.project_id === 5 && currentStatus === '50'
              ? 'Заявка допущена'
              : undefined
          "
        ></Status>
      </div>

      <div class="event-category__request__btns" v-if="!isLinkToStatement">
        <div
          class="event-category__request__btn--apply"
          :class="{
            'event-category__request__btn--tooltip':
              (dataArray?.project_id !== 5 &&
                currentShiftInfo &&
                !currentShiftInfo?.is_access_new_statement) ||
              (!this.isRT && this.dataArray?.project_id !== 5) ||
              (!isLFAccess && this.dataArray?.project_id === 5) ||
              (dataArray?.category_id === 21 && !this.isSPO && !isRT) ||
              (dataArray.project_id !== 4 && disabledFields['isNotStudent']) ||
              (dataArray.project_id === 1 && isTGBtnDisabled) ||
              (dataArray.project_id === 2 && isSGBtnDisabled) ||
              (!disabledFields['isCitizenRussia'] &&
                (dataArray?.category_id === 3 ||
                  dataArray?.category_id === 10)) ||
              (dataArray.project_id === 2 && is28YearsOld) ||
              (dataArray.project_id === 3 && is28YearsOld) ||
              !!allValidationsRequestBtn ||
              !validPeriodForSend,
          }"
          data-tooltip="Нет возможности подать заявку по одной из причин: отсутствие данных в профиле, неверный период подачи или несоответствие условиям конкурса."
        >
          <Button
            @click="createStatement"
            :disabled="
              (dataArray?.project_id !== 5 &&
                currentShiftInfo &&
                !currentShiftInfo?.is_access_new_statement) ||
              (!isRT && dataArray?.project_id !== 5) ||
              (!isLFAccess && dataArray?.project_id === 5) ||
              (dataArray?.category_id === 21 && !isSPO && !isRT) ||
              (dataArray.project_id !== 4 && disabledFields['isNotStudent']) ||
              (dataArray.project_id === 1 && isTGBtnDisabled) ||
              (dataArray.project_id === 2 && isSGBtnDisabled) ||
              (!disabledFields['isCitizenRussia'] &&
                (dataArray?.category_id === 3 ||
                  dataArray?.category_id === 10)) ||
              (dataArray.project_id === 2 && is28YearsOld) ||
              (dataArray.project_id === 3 && is28YearsOld) ||
              !!allValidationsRequestBtn ||
              !validPeriodForSend
            "
            :loading="isBtnLoading"
          >
            Подать заявку на мероприятие
          </Button>
        </div>
        <Button
          v-if="
            !disabledFields['isProfileFilled'] ||
            !disabledFields['isEducationFilled']
          "
          @click="goToProfile"
        >
          Заполнить профиль
        </Button>
      </div>
      <Button
        @click="$router.push({ name: 'Applications' })"
        secondary
        v-else-if="
          (!this.isRT && this.dataArray?.project_id !== 5) ||
          (!isLFAccess && this.dataArray?.project_id === 5) ||
          (dataArray?.category_id === 21 && !isSPO && !isRT) ||
          (dataArray.project_id !== 4 && disabledFields['isNotStudent']) ||
          (dataArray?.project_id === 3 && !isSPO) ||
          (dataArray?.project_id === 3 && is28YearsOld) ||
          (dataArray?.project_id === 2 && isSPO) ||
          (dataArray?.project_id === 2 && is28YearsOld)
        "
      >
        Мои заявки
      </Button>
      <div class="event-category__request__btn--has-apply" v-else>
        <Button
          secondary
          @click="openPopup"
          :loading="isDeleteBtnLoading"
          v-if="rollbackAllowed"
        >
          Отказ от участия
        </Button>
        <Button @click="goToStatement" secondary> Моя заявка </Button>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Blocks/Button";
import { createRequest } from "@/api/requestBuilder";
import requestConfigs from "@/api/requestConfigs";
import moment from "moment";
import { mapGetters, mapMutations, mapState } from "vuex";
import Status from "@/components/Blocks/Status.vue";
import Icon from "@/components/Blocks/Icon.vue";

export default {
  name: "EventPageCategory",
  components: { Icon, Status, Button },
  data() {
    return {
      isBtnLoading: false,
      isOldApplication: false,
    };
  },
  props: {
    hasApply: Boolean,
    dataArray: [Array, Object],
    projectInfo: Object,
    index: Number,
    categoriesCount: Number,
  },
  computed: {
    linkLF() {
      return {
        21: "https://liga-forum.ru/shift/first",
        22: "https://liga-forum.ru/shift/second",
        23: "https://liga-forum.ru/shift/third",
      };
    },
    className() {
      return {
        "event-category--hasApply": this.hasApply,
        // "event-category--alreadyApply": this.alreadyApply,
      };
    },
    validPeriodForSend() {
      return moment().isBetween(
        moment(this.dataArray?.stage.start_at),
        moment(this.dataArray?.stage.end_at),
        "day",
        "[]"
      );
    },
    openRegistrationStage() {
      return this.projectInfo?.settings?.stages?.data.find((item) => {
        return item?.stage_key === "open_registration";
      });
    },
    checkStatementEmployee() {
      return this.projectInfo?.settings?.stages?.data.find((item) => {
        return item?.stage_key === "check_statement_employee";
      });
    },
    selectWinnersPeriod() {
      return this.projectInfo?.settings?.stages?.data.find((item) => {
        return item?.stage_key === "select_winners";
      });
    },
    validPeriodForRollback() {
      return moment().isBetween(
        moment(this.openRegistrationStage?.start_at),
        moment(this.openRegistrationStage?.end_at),
        "day",
        "[]"
      );
    },
    validPeriodFor35Status() {
      return moment().isBetween(
        moment(this.checkStatementEmployee?.start_at),
        moment(this.checkStatementEmployee?.end_at),
        "day",
        "[]"
      );
    },
    validWinnersPeriod() {
      return moment().isBetween(
        moment(this.selectWinnersPeriod?.start_at),
        moment(this.selectWinnersPeriod?.end_at),
        "day",
        "[]"
      );
    },
    currentStatus() {
      return `${this.currentStatement?.status?.key}`;
    },
    rollbackAllowed() {
      let boolean;
      if (this.validPeriodForRollback) {
        if (
          this.dataArray?.project_id === 1 &&
          !this.disabledFields.isNotStudent
        ) {
          boolean =
            this.currentStatus === "0" ||
            this.currentStatus === "1" ||
            this.currentStatus === "50";
        }
        if (
          this.dataArray?.project_id === 2 &&
          !this.disabledFields.isNotStudent
        ) {
          boolean =
            this.currentStatus === "0" ||
            this.currentStatus === "1" ||
            this.currentStatus === "50";
        }
        if (
          this.dataArray?.project_id === 3 &&
          !this.disabledFields.isNotStudent
        ) {
          boolean =
            this.currentStatus === "0" ||
            this.currentStatus === "1" ||
            this.currentStatus === "50";
        }
        if (this.dataArray?.project_id === 4) {
          boolean =
            this.currentStatus === "0" ||
            this.currentStatus === "1" ||
            this.currentStatus === "50";
        }
        if (this.dataArray?.project_id === 5) {
          boolean =
            this.currentStatus === "0" ||
            this.currentStatus === "1" ||
            this.currentStatus === "50" ||
            this.currentStatus === "85" ||
            this.currentStatus === "100";
        }
      } else if (this.validPeriodFor35Status) {
        if (
          this.dataArray?.project_id === 2 &&
          !this.disabledFields.isNotStudent
        ) {
          boolean = this.currentStatus === "50";
        }
        if (
          this.dataArray?.project_id === 3 &&
          !this.disabledFields.isNotStudent
        ) {
          boolean = this.currentStatus === "50";
        }
        if (
          this.dataArray?.project_id === 5 &&
          !this.disabledFields.isNotStudent
        ) {
          boolean =
            this.currentStatus === "0" ||
            this.currentStatus === "1" ||
            this.currentStatus === "50" ||
            this.currentStatus === "85" ||
            this.currentStatus === "100";
        }
      } else if (this.validWinnersPeriod) {
        if (this.dataArray?.project_id === 5) {
          boolean =
            this.currentStatus === "50" ||
            this.currentStatus === "85" ||
            this.currentStatus === "100";
        }
      } else boolean = false;

      return this.isOldApplication ? false : boolean;
    },

    firstCourseExcellent() {
      return this.user?.info_course_level?.id === 1;
    },
    is28YearsOld() {
      return moment().subtract(28, "years").isAfter(moment(this.user.birthday));
    },
    vuzTypeValidations() {
      return this.user?.info_educational_establishment?.type?.key === 2;
    },
    //определяем свозможность подачи заявки в зависимости от данных пользователя и текущей категории
    allValidationsRequestBtn() {
      let boolean;
      if (this.dataArray?.category_id === 1) {
        boolean = this.firstCourseExcellent;
      }
      if (
        this.dataArray?.category_id === 5 ||
        this.dataArray?.category_id === 6 ||
        this.dataArray?.category_id === 7
      ) {
        boolean = this.vuzTypeValidations;
      }
      if (
        this.dataArray?.category_id === 11 ||
        this.dataArray?.category_id === 12 ||
        this.dataArray?.category_id === 13
      ) {
        boolean = !this.vuzTypeValidations;
      }
      return boolean;
    },
    currentCategoryStatements() {
      return this.statementsList.filter((i) => {
        return i.info_project.id === this.dataArray?.project_id;
      });
    },
    isTGBtnDisabled() {
      let boolean;
      if (this.dataArray.project_id === 1) {
        boolean =
          this.currentCategoryStatements?.length >= 0 &&
          this.currentCategoryStatements?.some((item) => {
            return (
              item.status?.key !== 45 &&
              item.version === this.dataArray?.version &&
              item.info_category?.id !== this.dataArray?.category_id
            );
          });
      }
      return boolean;
    },
    isSGBtnDisabled() {
      let boolean = false;
      // if (this.dataArray.project_id === 2) {
      //   boolean =
      //     this.currentCategoryStatements?.length >= 0 &&
      //     this.currentCategoryStatements?.some((item) => {
      //       return (
      //         item.status?.key !== 45 &&
      //         item.version === this.dataArray?.version &&
      //         ((this.dataArray?.category_id === 5 &&
      //           item.info_category?.id === 6) ||
      //           (this.dataArray?.category_id === 6 &&
      //             item.info_category?.id === 5))
      //       );
      //     });
      // }
      return boolean;
    },
    isLFAccess() {
      const currentDirection = this.directions?.info?.find(
        (item) => item?.liga_category_id === this.dataArray?.category_id
      );
      const currentType = this.user?.info_educational_establishment?.type?.key;

      return currentType
        ? currentDirection?.type_access?.includes(
            currentType === 1 ? "vuz" : "suz"
          )
        : false;
    },
    //определяем состояние кнопки в зависимости от текущих заявок на данный конкурс и статусов заявок
    isLinkToStatement() {
      let boolean;
      if (this.dataArray.project_id === 1) {
        boolean =
          this.currentCategoryStatements?.length >= 0 &&
          this.currentCategoryStatements?.some((item) => {
            return (
              item.status?.key !== 45 &&
              item.version === this.dataArray?.version &&
              item.info_category.id === this.dataArray?.category_id
            );
          });
      }
      if (this.dataArray.project_id === 2) {
        if (this.dataArray.status) boolean = this.dataArray.status.key !== 45;
      }
      if (this.dataArray.project_id === 3) {
        if (this.dataArray.status) boolean = this.dataArray.status.key !== 45;
      }
      if (this.dataArray.project_id === 4) {
        if (this.dataArray.status) boolean = this.dataArray.status.key !== 45;
      }
      if (this.dataArray.project_id === 5) {
        if (this.dataArray.status) boolean = this.dataArray.status.key !== 45;
      }
      return boolean;
    },
    currentStatement() {
      return this.statementsList?.find((item) => {
        return item.id === this.dataArray?.id;
      });
    },
    currentNomination() {
      let buffer = JSON.parse(
        JSON.stringify(this.currentStatement?.info_documents?.data)
      );

      return buffer
        ? buffer
            .sort((a, b) => {
              return (
                moment(b?.info_document?.created_at) -
                moment(a?.info_document?.created_at)
              );
            })
            .slice(-1)[0]?.info_project_tree
        : {};
    },
    currentLink() {
      let link = {};

      if (this.dataArray?.category_id === 2) {
        link = { name: "SocialActiveProfile" };
      }
      if (this.dataArray?.category_id === 3) {
        link = { name: "ExcellentStudentProfile" };
      }

      if (this.dataArray?.category_id === 5) {
        link = { name: "StudentYearIndividualNomination" };
      }
      if (this.dataArray?.category_id === 6) {
        link = { name: "StudentYearCollectiveNomination" };
      }
      if (this.dataArray?.category_id === 7) {
        link = { name: "StudentYearGrandPrixNomination" };
      }

      if (this.dataArray?.category_id === 10) {
        link = { name: "HousingStockProfile" };
      }

      if (this.dataArray?.category_id === 11) {
        link = { name: "AchievementYearIndividualNomination" };
      }
      if (this.dataArray?.category_id === 12) {
        link = { name: "AchievementYearCollectiveNomination" };
      }
      if (this.dataArray?.category_id === 13) {
        link = { name: "AchievementYearGrandPrixNomination" };
      }

      if (this.dataArray?.category_id === 21) {
        link = { name: "LigaForumFirstShiftProfile" };
      }
      if (this.dataArray?.category_id === 22) {
        link = { name: "LigaForumSecondShiftProfile" };
      }
      if (this.dataArray?.category_id === 23) {
        link = { name: "LigaForumThirdShiftProfile" };
      }

      return link;
    },
    isStatementAddmitted() {
      return +this.currentStatus === 50;
    },
    currentShiftInfo() {
      let info;
      if (this.dataArray?.project_id === 5) {
        info = this.directions?.info?.find((direction) => {
          return this.dataArray?.category_id === direction.liga_category_id;
        });
      }
      return info;
    },
    ...mapGetters("user", ["infoStatements"]),
    ...mapGetters(["directions"]),
    ...mapState("user", ["user", "disabledFields", "isSPO", "isRT"]),
    ...mapState(["statementsList", "isDeleteBtnLoading"]),
  },
  methods: {
    async goToProfile() {
      if (!this.disabledFields["isProfileFilled"]) {
        await this.$router.push({ name: "ProfileData" });
      } else if (!this.disabledFields["isEducationFilled"]) {
        await this.$router.push({ name: "Education" });
      }
    },
    async createStatement() {
      if (!this.isRT && this.dataArray?.project_id !== 5) return;
      if (this.dataArray?.project_id === 2 && this.isSPO) return;
      if (this.dataArray?.project_id === 3 && !this.isSPO) return;
      if (this.dataArray.project_id === 1 && this.isTGBtnDisabled) return;
      if (!this.disabledFields["isProfileFilled"]) {
        this.$store.commit("pushToTray", {
          text: "Не хватает данных в разделе 'Мои данные'",
          type: "error",
        });
        await this.$router.push({ name: "ProfileData" });
        return;
      }
      if (!this.disabledFields["isEducationFilled"]) {
        this.$store.commit("pushToTray", {
          text: "Не хватает данных в разделе 'Образование'",
          type: "error",
        });
        await this.$router.push({ name: "Education" });
        return;
      }

      this.isBtnLoading = true;
      await createRequest(requestConfigs.POSTCreateStatement, {
        jsonPayload: { category_id: this.dataArray?.category_id },
        routerPayload: { id: this.dataArray?.project_id },
      })
        .then((response) => {
          this.$store.commit("setCurrentCategoryData", {});
          this.$store.commit("setStatementId", response.data.id);
          this.$store.commit("setNominationValue", ["isOldApplication", false]);
          // this.$store.commit("pushToStatementsList", response.data);
        })
        .then(() => {
          this.$store.commit("setPortfolioDoc", "");
          this.$store.commit("setPortfolioDocId", "");
          this.$store.commit("clearHSDocs");
          this.$store.commit(
            "setCurrentCategoryId",
            this.dataArray?.category_id
          );
          this.$store.commit(
            "setCurrentCategoryProject",
            this.dataArray?.project_id
          );
          this.$store.commit(
            "setCurrentCategoryVersion",
            this.dataArray?.version
          );
          if (this.dataArray?.project_id === 2) {
            if (this.dataArray?.category_id === 5) {
              this.$store.commit("setNominationValue", [
                "individualNominationValue",
                "",
              ]);
            }
            if (this.dataArray?.category_id === 6) {
              this.$store.commit("setNominationValue", [
                "collectiveNominationValue",
                "",
              ]);
            }
            if (this.dataArray?.category_id === 7) {
              this.$store.commit("setNominationValue", [
                "granprixNominationValue",
                "",
              ]);
            }
          }
          if (this.dataArray?.project_id === 3) {
            if (this.dataArray?.category_id === 11) {
              this.$store.commit("setNominationValue", [
                "AYindividualNominationValue",
                "",
              ]);
            }
            if (this.dataArray?.category_id === 12) {
              this.$store.commit("setNominationValue", [
                "AYcollectiveNominationValue",
                "",
              ]);
            }
            if (this.dataArray?.category_id === 13) {
              this.$store.commit("setNominationValue", [
                "AYgranprixNominationValue",
                "",
              ]);
            }
          }
          if (this.dataArray?.project_id === 5) {
            if (this.dataArray?.category_id === 21) {
              this.clearShiftStatementData("firstShiftStatementData");
            }
            if (this.dataArray?.category_id === 22) {
              this.clearShiftStatementData("secondShiftStatementData");
            }
            if (this.dataArray?.category_id === 23) {
              this.clearShiftStatementData("thirdShiftStatementData");
            }
          }
        })
        .then(() => {
          this.$router.push({ name: this.dataArray?.link });
        })
        .finally(() => {
          this.isBtnLoading = false;
        });
    },
    goToStatement() {
      this.$store.commit("setCurrentCategoryData", this.currentStatement);
      this.$store.commit("setCurrentCategoryId", "");
      this.$store.commit("setCurrentCategoryProject", "");
      this.$store.commit("setCurrentCategoryVersion", "");
      this.$store.commit("setStatementId", "");
      if (this.dataArray?.project_id === 2) {
        if (this.dataArray?.category_id === 5) {
          this.$store.commit("setNominationValue", [
            "individualNominationValue",
            this.currentNomination?.id,
          ]);
        }
        if (this.dataArray?.category_id === 6) {
          this.$store.commit("setNominationValue", [
            "collectiveNominationValue",
            this.currentNomination?.id,
          ]);
        }
        if (this.dataArray?.category_id === 7) {
          this.$store.commit("setNominationValue", [
            "granprixNominationValue",
            this.currentNomination?.id,
          ]);
        }
      }
      if (this.dataArray?.project_id === 3) {
        if (this.dataArray?.category_id === 11) {
          this.$store.commit("setNominationValue", [
            "AYindividualNominationValue",
            this.currentNomination?.id,
          ]);
        }
        if (this.dataArray?.category_id === 12) {
          this.$store.commit("setNominationValue", [
            "AYcollectiveNominationValue",
            this.currentNomination?.id,
          ]);
        }
        if (this.dataArray?.category_id === 13) {
          this.$store.commit("setNominationValue", [
            "AYgranprixNominationValue",
            this.currentNomination?.id,
          ]);
        }
      }
      this.$router.push(this.currentLink);
    },
    openPopup() {
      this.$store.commit("setCurrentPopup", {
        name: "PopupRevokeApplication",
        isShown: true,
        props: { id: this.currentStatement.id },
      });
    },
    ...mapMutations(["clearShiftStatementData"]),
  },
  mounted() {
    this.isOldApplication =
      this.dataArray?.version !== this.currentStatement?.version;
  },
  watch: {
    dataArray: {
      handler: function (value) {
        this.isOldApplication =
          this.currentStatement?.version !== value.version;
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.event-category {
  margin-bottom: 32px;
  @include adaptive(phone) {
    margin-bottom: 15px;
  }
  &--alreadyApply {
    .event-category__request__btn--apply button:first-child {
      display: none;
    }
  }
  &--hasApply {
    .event-category__request__btn--apply {
      display: none;
    }
    .event-category__request__btn--has-apply {
      display: flex;
      gap: 12px;
      @include adaptive(tablet-small) {
        flex-direction: column;
        & > button:last-child {
          order: -1;
        }
      }
    }
  }
  &__title {
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 2.2rem;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    grid-gap: 5px;
    a {
      display: block;
      line-height: 1;
      color: var(--text--color);
      i {
        font-size: 20px;
      }
      cursor: pointer;
      &:hover {
        -webkit-text-stroke-width: 0.05rem;
      }
    }
    @include adaptive(phone) {
      font-size: 1.6rem;
      line-height: 1.9rem;
      margin-bottom: 12px;
    }
  }
  &__profile {
    font-size: 1.4rem;
    line-height: 1.7rem;
    font-weight: 500;
    margin-bottom: 16px;
    &__title {
      color: var(--banner--color);
    }
  }
  &__request {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 20px;
    border-radius: 5px;
    background-color: var(--background--request);
    @include adaptive(tablet-small) {
      flex-direction: column;
      gap: 20px;
    }

    &__date {
      font-size: 1.4rem;
      line-height: 1.7rem;
      font-weight: 500;
      align-self: center;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      grid-gap: 5px;
      &__title {
        color: var(--banner--color);
        font-size: 1.4rem;
        line-height: 1.7rem;
        font-weight: 400;
      }
    }
    &__btns {
      display: flex;
      grid-gap: 10px;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }
    &__btn {
      &--apply {
        position: relative;
        &[data-tooltip] {
          &::after {
            content: none;
          }
        }
      }
      &--has-apply {
        display: flex;
        grid-gap: 10px;
        flex-wrap: wrap;
        justify-content: center;
      }
      &--tooltip {
        &[data-tooltip]::after {
          content: attr(data-tooltip);
          width: 360px;
          right: -25px;
          left: unset;
        }
        &[data-tooltip]:hover::after {
          opacity: 1;
          top: 3em;
        }
        @include adaptive(tablet-big) {
          &[data-tooltip]::after {
            width: 290px;
            right: -15px;
          }
        }
      }
    }
  }
}
</style>
